import React from "react"
import _ from "lodash"
import moment from "moment"

import { graphql, Link } from "gatsby"
import Cite from "citation-js"
import ReactMarkdown from "react-markdown"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { BiChevronsRight } from "react-icons/bi"

import "../fragments"
import Layout from "../components/layout"
import Features from "../components/features/features"
import News from "../components/news"
import Calendar from "../components/calendar"
import bibtex from "raw-loader!./research/publications.bib"

const intro = `
  Welcome! I'm Bas, a classical singer, researcher, and graphic designer from The Netherlands.
`
// Over the past years I've been working on my PhD research in computational 
// musicology in Amsterdam, while also studying classical voice in Utrecht.
// A selection of projects I have worked on has been collected here. 

// Please get in touch if you have questions or want to work together :-)

export default function Home({ data }) {
  
  const bibliography = new Cite(bibtex).sort()
  bibliography.data = bibliography.data.reverse()
  // const entries = getRawEntries(bibliography)
  const today = new Date()
  const featuredProjects = _.sortBy(
    [
      ...data.featuredResearch.nodes, 
      ...data.featuredDesign.nodes,
      ...data.featuredMusic.nodes
    ],
    "date"
  ).reverse().filter(project => {
    const days = moment().diff(moment(project.date), 'days')
    return days < 1.5 * 365
  }).slice(0, 9)

  const concerts = data.concerts.nodes.filter(event =>
    new Date(event.start.dateTime) > today)
  return (
    <Layout className="page-home">
      <Container>
        <Row style={{maxWidth: 1200}}>
          <Col md={8}>
            <ReactMarkdown children={intro} className="lead text-primary mb-5" />
          </Col>
        </Row>
      </Container>

      <Container className="mt-5">
        <Row xs="1" lg="2">
          <Col lg="8" className="mb-5 pe-5">
            <h2 className="striped-header mb-4"><span>Updates</span></h2>
            <News items={data.latestNews.nodes} />
          </Col>
          <Col lg="4" className="mb-4">
            <h2 className="striped-header mb-5"><span>Concerts</span></h2>
            <Calendar events={concerts} />
          </Col>
        </Row>
      </Container>

      <Container className="mt-5">
        <Row>
          <h2 className="striped-header mb-5"><span>Recent projects</span></h2>
          <Features projects={featuredProjects} bibliography={bibliography.data} />
        </Row>

        <Row>
          <Col>
            <Link to="/en/design" className="btn btn-large btn-primary-outline mr-2">
              More research <BiChevronsRight />
            </Link>
            <Link to="/en/research" className="btn btn-large btn-primary-outline  mr-2">
              More design <BiChevronsRight />
            </Link>
            <Link to="/en/music" className="btn btn-large btn-primary-outline">
              More music <BiChevronsRight />
            </Link>
          </Col>
        </Row>
      </Container>
    </Layout>  
  )
}

export const query = graphql`
  query IndexQuery {

    concerts: allCalendarEvent(
      sort: {fields: start___dateTime, order: ASC}, 
      filter: {start: {dateTime: {glob: "2023*"}}}
    ) {
      nodes {
        id
        start {
          dateTime
        }
        end {
          dateTime
        }
        title: summary
        details: description
        location
      }
    }

    latestNews: allNews(
      sort: {fields: date, order: DESC}, 
      limit: 8
    ) {
      nodes {
        date
        message
        tags
      }
    }

    featuredResearch: allResearch(
      filter: {
        status: {eq: published},
        featured: {eq: true},
        date: {ne: null}
      },
      sort: {fields: date, order: DESC}, 
      limit: 6
    ) {
      nodes {
        ...researchProperties
      }
    }

    featuredDesign: allDesign(
      filter: {
        status: {eq: published},
        featured: {eq: true},
        date: {ne: null}
      },
      sort: {fields: date, order: DESC}, 
      limit: 6
    ) {
      nodes {
        ...designProperties
      }
    }

    featuredMusic: allMusic(
      filter: {
        status: {eq: published},
        featured: {eq: true},
        date: {ne: null}
      },
      sort: {fields: date, order: DESC}, 
      limit: 6
    ) {
      nodes {
        ...musicProperties
      }
    }
  }
`

